<template>
    <div class="wrapper">
        <Header :code="projectCode"></Header>
        <div class="page-title icon"><Icon type="md-sad"/></div>
        <div class="content">
            <div class="main-content">

                <div class="prompt">
                    <div class="headline">温馨提示：</div>
                    <div class="section" v-if="viewForm.status===5">
                        您的链接地址有误，请核实后重新打开！
                    </div>
                    <div class="section" v-else>
                        抱歉，您已投递过该职位或已达到本项目最多投递数量，请查看<a @click="goHome(projectCode)">其他职位</a>，谢谢！
                    </div>
                </div>

            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "../../components/pc/Header";
import Footer from "../../components/pc/Footer"
import {getProjectByCode} from "../../api/client-api";

export default {
    components: {
        Header: Header,
        Footer: Footer
    },
    data() {
        return {
            viewForm: {},
            projectCode: null,
            status: '',
        }
    },
    methods: {
        getProject() {
            getProjectByCode({code: this.projectCode}).then((res) => {
                if (res.data.data) {
                    this.viewForm = res.data.data
                }else {
                    this.viewForm = {}
                    this.viewForm.status = 5
                }
            }).catch((err) => {
                console.log('err===', err)
            })
        },
        goHome(code) {
            this.$router.push({name: 'project', params: {id: code}, query: {tab: 'position'}});
        }
    },
    mounted() {
        this.projectCode = this.$route.query.projectCode;
        this.getProject()
    }
};
</script>

<style scoped>

</style>
