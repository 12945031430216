<template>
  <div class="wrapper has_nav_bar">
      <van-nav-bar title="温馨提示" fixed />
      <div class="content">
          <div class="main-content">
            <div class="prompt">
              <div class="section">
                <van-icon name="warning-o" />
                <p v-if="viewForm.status===5">您的链接地址有误，请核实后重新打开！</p>
                <p v-else>抱歉，您已投递过该职位或已达到本项目最多投递数量，请查看<a>其他职位</a>，谢谢！</p>
              </div>
            </div>
          </div>
      </div>
      <div class="footer">
      </div>
      <div class="tab_bar">
          <van-tabbar>
              <van-tabbar-item name="home" icon="wap-home">招聘主页</van-tabbar-item>
              <van-tabbar-item :badge='messageCounts' name="message" icon="chat" @click="goMessageCenter">消息中心</van-tabbar-item>
              <van-tabbar-item name="application" icon="column" @click="goMyApplication">我的报名</van-tabbar-item>
              <van-tabbar-item name="my" icon="manager" @click="goUserCenter">个人中心</van-tabbar-item>
          </van-tabbar>
      </div>
  </div>
</template>

<script>
import {getProjectByCode} from "../../api/client-api";

export default {
  data() {
      return {
          messageCounts: "",
          viewForm: {},
      }
  },
  methods: {
      goMessageCenter() {
          this.$router.push({name: 'messageCenter', query: {projectCode: this.projectCode}});
      },
      goMyApplication() {
          this.$router.push({name: 'myApplication', query: {projectCode: this.projectCode}});
      },
      goUserCenter() {
          this.$router.push({name: 'userCenter', query: {projectCode: this.projectCode}});
      },
      getProject() {
          getProjectByCode({code: this.projectCode}).then((res) => {
              if (res.data.data) {
                  this.viewForm = res.data.data
              }else {
                  this.viewForm = {}
                  this.viewForm.status = 5
              }
          }).catch((err) => {
              console.log('err===', err)
          })
      },
  },
  mounted() {
      this.projectCode = this.$route.query.projectCode;
      this.getProject()
  }
};
</script>

<style scoped>

</style>
